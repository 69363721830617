import { Translation } from 'react-i18next';
import { authRoles } from 'app/auth';

const navigationConfig = [
	{
		id: 'applications',
		title: <Translation>{t => t('Applications')}</Translation>,
		type: 'group',
		icon: 'apps',
		children: [
			{
				id: 'dashboards',
				title: <Translation>{t => t('Dashboards')}</Translation>,
				type: 'collapse',
				icon: 'dashboard',
				auth: authRoles.admin,
				children: [
					{
						id: 'analytics-dashboard',
						title: <Translation>{t => t('Analytics')}</Translation>,
						type: 'item',
						url: '/apps/dashboards/analytics',
						auth: authRoles.admin
					}
				]
			},
			{
				id: 'reports',
				title: <Translation>{t => t('Reports')}</Translation>,
				type: 'collapse',
				icon: 'assignment',
				auth: [...authRoles.admin, ...authRoles.diller, ...authRoles.productModerator],
				children: [
					{
						id: 'admin-report',
						title: <Translation>{t => t('Отчет босса')}</Translation>,
						type: 'item',
						url: '/apps/report/admin-report',
						auth: [...authRoles.admin]
					},
					{
						id: 'product-report',
						title: <Translation>{t => t('Product report')}</Translation>,
						type: 'item',
						url: '/apps/report/product-report',
						auth: [...authRoles.admin, ...authRoles.productModerator]
					},
					{
						id: 'order-report',
						title: <Translation>{t => t('Order report')}</Translation>,
						type: 'item',
						url: '/apps/report/order-report',
						auth: [...authRoles.admin, ...authRoles.diller, ...authRoles.productModerator]
					}
				]
			},
			{
				id: 'users',
				title: <Translation>{t => t('Users')}</Translation>,
				type: 'collapse',
				icon: 'account_circle',
				auth: [
					...authRoles.admin,
					...authRoles.diller,
					...authRoles.dillerHelper,
					...authRoles.serviceMan,
					...authRoles.salesAgent,
					...authRoles.productModerator
				],
				children: [
					{
						auth: authRoles.admin,
						id: 'product-moderator',
						title: <Translation>{t => t('Product Moderator')}</Translation>,
						type: 'item',
						url: '/apps/users/product-moderator'
					},
					{
						auth: authRoles.admin,
						id: 'partner-moderator',
						title: <Translation>{t => t('Partner Moderator')}</Translation>,
						type: 'item',
						url: '/apps/users/partner-moderator'
					},
					{
						auth: [...authRoles.admin, ...authRoles.productModerator],
						id: 'user-diller',
						title: <Translation>{t => t('Dillers')}</Translation>,
						type: 'item',
						url: '/apps/users/diller'
					},
					{
						auth: authRoles.diller,
						id: 'diller-helper',
						title: <Translation>{t => t('Diller Helper')}</Translation>,
						type: 'item',
						url: '/apps/users/diller-helper'
					},
					{
						auth: [...authRoles.diller, ...authRoles.dillerHelper],
						id: 'sales-agent',
						title: <Translation>{t => t('Sales Agent')}</Translation>,
						type: 'item',
						url: '/apps/users/sales-agent'
					},
					{
						auth: authRoles.admin,
						id: 'warehouse-man',
						title: <Translation>{t => t('Warehouse Man')}</Translation>,
						type: 'item',
						url: '/apps/users/warehouse-man'
					},
					{
						auth: authRoles.admin,
						id: 'service-man',
						title: <Translation>{t => t('Service Man')}</Translation>,
						type: 'item',
						url: '/apps/users/service-man'
					},
					{
						auth: authRoles.serviceMan,
						id: 'service-man-helper',
						title: <Translation>{t => t('Service Man Helper')}</Translation>,
						type: 'item',
						url: '/apps/users/service-man-helper'
					},
					{
						auth: [...authRoles.admin],
						id: 'cashier',
						title: <Translation>{t => t('Cashier')}</Translation>,
						type: 'item',
						url: '/apps/users/cashier'
					},
					{
						auth: authRoles.salesAgent,
						id: 'client',
						title: <Translation>{t => t('Client')}</Translation>,
						type: 'item',
						url: '/apps/users/client'
					}
				]
			},
			// {
			// 	id: 'order-product',
			// 	title: <Translation>{t => t('Order product')}</Translation>,
			// 	type: 'collapse',
			// 	icon: 'view_compact',
			// 	auth: authRoles.productModerator,
			// 	children: [
			// 		{
			// 			auth: authRoles.productModerator,
			// 			id: 'all-order-products',
			// 			title: <Translation>{t => t('All Order product')}</Translation>,
			// 			type: 'item',
			// 			url: '/apps/order-product',
			// 			exact: true
			// 		}
			// 	]
			// },
			{
				id: 'service-center',
				title: <Translation>{t => t('Service Center')}</Translation>,
				type: 'collapse',
				icon: 'view_compact',
				auth: [...authRoles.serviceMan, ...authRoles.admin, ...authRoles.serviceManHelper],
				children: [
					{
						auth: authRoles.admin,
						id: 'all-service-center',
						title: <Translation>{t => t('All Service Center')}</Translation>,
						type: 'item',
						url: '/apps/service-center',
						exact: true
					},
					{
						auth: [...authRoles.serviceMan],
						id: 'add-service-center',
						title: <Translation>{t => t('Service Center Products')}</Translation>,
						type: 'item',
						url: '/apps/service-center-products',
						exact: true
					},
					{
						auth: [...authRoles.serviceMan, ...authRoles.serviceManHelper],
						id: 'service-center-orders',
						title: <Translation>{t => t('Service Center Orders')}</Translation>,
						type: 'item',
						url: '/apps/service-center-orders',
						exact: true
					}
				]
			},
			{
				id: 'warehouses',
				title: <Translation>{t => t('Warehouses')}</Translation>,
				type: 'collapse',
				icon: 'view_compact',
				auth: [...authRoles.admin, authRoles.warehouseMan, ...authRoles.productModerator],
				children: [
					{
						auth: [...authRoles.admin, ...authRoles.productModerator],
						id: 'all-warehouses',
						title: <Translation>{t => t('All Warehouses')}</Translation>,
						type: 'item',
						url: '/apps/warehouses',
						exact: true
					},
					{
						auth: authRoles.warehouseMan,
						id: 'add-warehouses-products',
						title: <Translation>{t => t('Warhouse Products')}</Translation>,
						type: 'item',
						url: '/apps/warehouses-products',
						exact: true
					},
					{
						auth: authRoles.warehouseMan,
						id: 'add-warehouses-order-requests',
						title: <Translation>{t => t('Warhouse Order Requests')}</Translation>,
						type: 'item',
						url: '/apps/warehouses-order-requests',
						exact: true
					},
					{
						auth: authRoles.warehouseMan,
						id: 'warehouses-returned-products',
						title: <Translation>{t => t('Returned Products')}</Translation>,
						type: 'item',
						url: '/apps/warehouse-returned-products',
						exact: true
					},
					{
						auth: authRoles.warehouseMan,
						id: 'warehouses-imported-products',
						title: <Translation>{t => t('All Imports')}</Translation>,
						type: 'item',
						url: '/apps/imports',
						exact: true
					}
				]
			},
			{
				id: 'products',
				title: <Translation>{t => t('Products')}</Translation>,
				type: 'collapse',
				icon: 'import_contacts',
				auth: [...authRoles.admin, ...authRoles.productModerator],
				children: [
					{
						auth: [...authRoles.admin, ...authRoles.productModerator],
						id: 'all-products',
						title: <Translation>{t => t('All Products')}</Translation>,
						type: 'item',
						url: '/apps/products',
						exact: true
					},
					{
						auth: authRoles.productModerator,
						id: 'add-products',
						title: <Translation>{t => t('Add Products')}</Translation>,
						type: 'item',
						url: '/apps/products/create-edit',
						exact: false
					},
					{
						auth: authRoles.productModerator,
						id: 'add-products',
						title: <Translation>{t => t('Поиск товаров')}</Translation>,
						type: 'item',
						url: '/apps/products/search',
						exact: false
					}
				]
			},
			{
				id: 'imports',
				title: <Translation>{t => t('Imports')}</Translation>,
				type: 'collapse',
				icon: 'import_export',
				auth: [...authRoles.admin, ...authRoles.productModerator],
				children: [
					{
						auth: [...authRoles.admin, ...authRoles.productModerator],
						id: 'all-imports',
						title: <Translation>{t => t('All Imports')}</Translation>,
						type: 'item',
						url: '/apps/imports',
						exact: true
					}
				]
			},
			{
				auth: [...authRoles.admin, ...authRoles.partnerModerator],
				id: 'partner',
				title: <Translation>{t => t('Partner')}</Translation>,
				type: 'item',
				icon: 'view_compact',
				url: '/apps/partners'
			},
			{
				id: 'diller',
				title: <Translation>{t => t('Diller')}</Translation>,
				type: 'collapse',
				icon: 'people_outline',
				auth: [...authRoles.diller, ...authRoles.dillerHelper],
				children: [
					{
						auth: [...authRoles.diller, ...authRoles.dillerHelper],
						id: 'diller-products',
						title: <Translation>{t => t('Diller Products')}</Translation>,
						type: 'item',
						url: '/apps/diller-products'
					},
					{
						auth: [...authRoles.diller, ...authRoles.dillerHelper],
						id: 'self-orders',
						title: <Translation>{t => t('Self orders')}</Translation>,
						type: 'item',
						url: '/apps/diller/orders',
						exact: true
					},
					{
						auth: [...authRoles.diller, ...authRoles.dillerHelper],
						id: 'diller-transfers',
						title: <Translation>{t => t('Diller Transfers')}</Translation>,
						type: 'item',
						url: '/apps/diller/transfers',
						exact: true
					},
					{
						auth: [...authRoles.diller, ...authRoles.dillerHelper],
						id: 'sa-orders-by-diller',
						title: 'Продажи дилера',
						type: 'item',
						url: '/apps/diller/ordersByDiller',
						exact: true
					},
					{
						auth: [...authRoles.diller, ...authRoles.dillerHelper],
						id: 'sa-orders',
						title: <Translation>{t => t('Sales agent orders')}</Translation>,
						type: 'item',
						url: '/apps/diller/sa-orders',
						exact: true
					},
					{
						auth: [...authRoles.diller, ...authRoles.dillerHelper],
						id: 'return-goods',
						title: <Translation>{t => t('Return / Outlet')}</Translation>,
						type: 'item',
						url: '/apps/diller/return-goods',
						exact: true
					}
				]
			},
			{
				auth: authRoles.salesAgent,
				id: 'sales-agents',
				title: <Translation>{t => t('Sales Agents')}</Translation>,
				type: 'item',
				icon: 'people_outline',
				url: '/apps/sales-agents/orders'
			},
			{
				auth: authRoles.salesAgent,
				id: 'analytics-sa',
				title: <Translation>{t => t('Аналитика продаж ')}</Translation>,
				type: 'item',
				icon: 'people_outline',
				url: '/apps/report/analytics-sa'
			},
			{
				auth: authRoles.diller,
				id: 'analytics-diller',
				title: <Translation>{t => t('Аналитика продаж')}</Translation>,
				type: 'item',
				icon: 'people_outline',
				url: '/apps/report/analytics-diller'
			},
			{
				id: 'expenditures',
				title: <Translation>{t => t('Expenditures')}</Translation>,
				type: 'collapse',
				icon: 'account_balance',
				auth: [...authRoles.admin, ...authRoles.cashier],
				children: [
					{
						auth: [...authRoles.admin, ...authRoles.cashier],
						id: 'expenditure',
						title: <Translation>{t => t('Expenditure')}</Translation>,
						type: 'item',
						exact: true,
						url: '/apps/expenditures'
					},
					{
						auth: [...authRoles.admin, ...authRoles.cashier],
						id: 'expenditure-categories',
						title: <Translation>{t => t('Expenditure Categories')}</Translation>,
						type: 'item',
						url: '/apps/expenditures/expenditure-categories'
					}
				]
			},
			{
				id: 'discount',
				title: <Translation>{t => t('Discount')}</Translation>,
				type: 'collapse',
				icon: 'local_offer',
				auth: authRoles.admin,
				children: [
					{
						auth: authRoles.admin,
						id: 'standart-discount',
						title: <Translation>{t => t('Standart')}</Translation>,
						type: 'item',
						exact: true,
						url: '/apps/discount/standart'
					},
					{
						auth: authRoles.admin,
						id: 'dynamic-discount',
						title: <Translation>{t => t('Dynamic')}</Translation>,
						type: 'item',
						url: '/apps/discount/dynamic'
					}
				]
			},
			{
				id: 'settings',
				title: <Translation>{t => t('Settings')}</Translation>,
				type: 'collapse',
				icon: 'settings',
				auth: authRoles.admin,
				children: [
					{
						auth: authRoles.admin,
						id: 'currency',
						title: <Translation>{t => t('Currency')}</Translation>,
						type: 'item',
						url: '/apps/settings/currency'
					},
					{
						auth: authRoles.admin,
						id: 'category',
						title: <Translation>{t => t('Category')}</Translation>,
						type: 'item',
						url: '/apps/settings/category'
					},
					{
						auth: authRoles.admin,
						id: 'general settings',
						title: <Translation>{t => t('General settings')}</Translation>,
						type: 'item',
						url: '/apps/settings/general'
					},
					{
						auth: authRoles.admin,
						id: 'income type',
						title: <Translation>{t => t('Income Type')}</Translation>,
						type: 'item',
						url: '/apps/settings/income-type'
					},
					{
						auth: authRoles.admin,
						id: 'admin-reports-getters',
						title: <Translation>{t => t('Получатели')}</Translation>,
						type: 'item',
						url: '/apps/settings/admin-report-gertters'
					}
				]
			}
		]
	}
];

export default navigationConfig;
